@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.woff2') format('woff2'),
    url('./fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}


.toast__custom {
  align-items: center !important;
  border: 1px solid #D0D5DD !important;
  padding: 12px 16px !important;
}

.toast__custom>div {
  justify-content: initial !important;
  line-height: 24px !important;
  font-size: 14px;
}

.loader_circle,
.loader_circle:after {
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
}

.loader_circle {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: 0.2em solid #d8d8d8;
  border-left-color: #000;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s linear infinite;
  animation: load8 1.1s linear infinite;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container__layout {
  height: calc(100vh - 75px);
}

.tagify__input::before {
  font-style: normal;
  font-weight: 510;
  font-size: 12px;
  line-height: 16px;

}

.tagify {
  min-height: 164px;
  width: 100%;
  display: inline-block !important;
  border-radius: 6px;
}

.tagify__tag+.tagify__input::before {
  display: none
}



.placeholder-content {
  height: 15px;
  overflow: hidden;
  -webkit-animation-duration: 1.7s;
  animation-duration: 1.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeholderAnimate;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px;
  border-radius: 3px;
}

.placeholder-content.placeholder-content-dark,
.dark .placeholder-content {
  background: #ffffff24;
}

.placeholder-content-10 {
  height: 10px;
}

.placeholder-content-no-animation {
  animation: none;
}

@-webkit-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }

  100% {
    background-position: 650px 0;
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }

  100% {
    background-position: 650px 0;
  }
}


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background-image: url('../public/images/icons/arrow-down.svg');
  background-position: center right 10px;
  padding-right: 25px !important;
  background-repeat: no-repeat;
  background-size: 9px;
}

.bg__nodata {
  position: relative;
}

.bg__nodata::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../public/images/icons/untitledui/nodata-bg.svg');
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.1;

  pointer-events: none;
  /* Ensures the pseudo-element doesn’t block interactions with the div */
}

.top__nav__dashboard__link.active,
.border-brand-600.top__nav__dashboard__link_active {
  border-color: #7f56d9;
  line-height: 1.1;
}

.group__details__filter .filter__group {
  display: none;
}

.svg__24>svg {
  width: 24px;
  height: 24px;
}

.svg__32>svg {
  width: 32px;
  height: 32px;
}

.svg__48>svg {
  width: 48px;
  height: 48px;
}

.domains_tagify .tagify {
  min-height: 44px;
  padding-left: 4px;
}

.domains_tagify .tagify__tag {
  margin-top: 8px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  background-color: #F2F4F7;
}

.dark .tagify__tag {
  background-color: #283961;
}

.dark .tagify__tag__removeBtn {
  color: white;
}

.dark .tagify__tag>div::before {
  opacity: 0;
}

.dark .tagify__tag>div {
  color: white;
}


.tagify__tag-text {
  word-break: break-word !important;
}

.modal__open #root {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.workspace__form__modal form>div {
  display: block;
}

.workspace__form__modal form>div div.w-3\/12 {
  width: 100%;
  margin-bottom: 12px;
}

.workspace__form__modal form>div div.w-5\/12 {
  width: 100%;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-4%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in {
  animation: slideIn 0.5s ease forwards;
  /* Adjust animation duration as needed */
}


.workspace-form .workspace-form-controls {
  position: absolute;
  right: 0;
  top: 0;
}

.workspace-discovery-controls {
  position: absolute;
  right: 0;
  top: 0;
}

table th {
  padding: 12px 24px !important;
}

table td {
  padding: 15px 24px !important;
}

svg.tick__bg-red-600 polygon {
  fill: var(--error-500);
}

svg.tick__bg-warning-600 {
  fill: var(--warning-500);
}


.bg-error-100.border-y-error-400 {
  border-top: 1px solid var(--error-400) !important;
  border-bottom: 1px solid var(--error-400) !important;
  background-color: var(--error-100) !important;
}

.bg-warning-100.border-y-warning-400 {
  border-top: 1px solid var(--warning-400) !important;
  border-bottom: 1px solid var(--warning-400) !important;
}

.dark .bg-error-100.border-y-error-400 {
  border-top: 1px solid var(--error-50) !important;
  border-bottom: 1px solid var(--error-50) !important;
  background-color: var(--error-25) !important;
}

.dark .bg-warning-100.border-y-warning-400 {
  border-top: 1px solid var(--yellow-50) !important;
  border-bottom: 1px solid var(--yellow-50) !important;
  background-color: var(--yellow-25) !important;
}

.sidebar__heading__wrapper {
  height: 24px;
}

.sidebar__item__menu {
  height: 40px;
}

.sidebar__item__menu__icon {
  min-width: 20px;
}

.sidebar__item__menu__text {
  white-space: nowrap;
}

.sidebar__mini .sidebar__item__menu__text {
  display: none;
}

.sidebar__mini .sidebar__item__menu__icon {
  margin-right: 0;
}

.sidebar__mini .sidebar__heading {
  display: none;
}

.sidebar__mini .sidebar__heading__wrapper {
  justify-content: center;
}

.add_bottom_border:before {
  content: "";
  width: 1px;
  height: 34%;
  position: absolute;
  background: #cbcbcb;
  top: 100%;
  left: 20px;
}

.ellipsis_text_icon {
  /* position: relative; */
  /* padding: 3px; */
  /* background: #000000; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
  /* align-items: center;
    justify-content: center;
    border-radius: 4px;
    left: 100%;
    top: 4px; */
  cursor: pointer;
}

.ellipsis_text_icon_parent {
  visibility: hidden;
  pointer-events: none;
  margin-left: 8px;
}

.ellipsis_text_showcopy .ellipsis_text_icon_parent {
  visibility: visible;
  pointer-events: initial;
}


.stripe__loader__container {
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #4e21ff, #ccc0ff);
  z-index: 1000;
}

.stripe__loader {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #4e21ff, #ccc0ff);
  animation: moveGradient 2s linear infinite;
  background-size: 200% 100%;
}

@keyframes moveGradient {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.register__slider .react-multi-carousel-dot-list {
  position: absolute;
  left: 0;
  z-index: 0;
  max-width: fit-content;
  right: 0;
  margin: auto;
  bottom: 2px;
}

.react-multi-carousel-dot button {
  border: none !important;
  background: #EAECF0;
  margin-right: 6px !important;
  margin-left: 6px !important;
  height: 8px !important;
  width: 8px !important;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: #7F56D9 !important;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.install__integration div.absolute.p-6 {
  position: initial;
  padding: 0;
  border: 0;
}

.loader_circle.loader_loading {
  border: 0.2em solid #7F56D9;
  border-left-color: #E9D7FE;
  font-size: 18px;
}

.loader_circle.loader_loading,
.loader_circle.loader_loading:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}


.bg__gradient {
  background: linear-gradient(0deg, #EBC0FD 0%, #D9DED8 100%);
}

.bg__gradient__onboarding {
  background: linear-gradient(180deg, #a5c0ee57 0%, #fbc5ec4f 100%)
}

.workspace__onboarding .active__workspace,
.workspace__onboarding .workspace__launch,
.workspace__onboarding .workspace__delete {
  display: none;
}

.dark img[src*="https://ui-avatars.com/api/?"] {
  filter: invert(1);
  border: 1px solid;
}

.pros__iframe__wrapper__drawer {
  overflow: initial;
}

#pros__iframe__wrapper {
  /* width: 100%; */
  width: 400px;
  height: 100%;
  /* height: calc(100vh - 73px); */
  /* overflow: hidden; */
  /* position: absolute;
  top: 73px;
  right: 0; */
  background: #f2f4f7;
  /* box-shadow: -8px 0px 10px #cdcdcd; */
  /* margin-right: -30px;
  margin-top: -28px; */
  transition: transform 0.5s ease-in-out 0s, width 0.1s ease-in-out 0s;
  position: relative;
}

#pros__iframe__wrapper iframe {
  width: 100%;
  height: 100%;
}

#pros__iframe__wrapper.pros__iframe__wrapper__company {
  width: 840px;
}

#pros__iframe__wrapper__hide {
  position: absolute;
  left: -24px;
  background: #f2f4f7;
  padding: 8px 8px;
  border-radius: 6px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {

  .workspace-form .workspace-form-controls,
  .workspace-discovery-controls {
    position: initial;
  }

  .workspace-discovery-controls {
    margin-top: 20px;
  }

  .drawer__dialog__panel {
    max-width: 100vw;
    min-width: 100vw;
  }
}

.custom-scrollable-dropdown::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollable-dropdown::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.custom-scrollable-dropdown::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 4px;
}

.custom-scrollable-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}