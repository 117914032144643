@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: #EAECF0;
    --input: #D0D5DD;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --purple: 259 63% 59%;
    --purple-foreground: 0 100% 100%;

    --smoke: 240 20% 99%;
    --paragraph: 216 18% 34%;
    --smokegray: 210 20% 98%;

    --text-900: 220 43% 11%; 
    /* 101828 primary - 900 */

     --text-700: 218 24% 27%; 
    /* 344054 primary - 700 */

    --text-600: 216 18% 34%; 
    /* 475467 primary - 600 */

    --text-500: 221 13% 46%; 
    /* 667085 primary - 500 */

    --text-400: 218 15% 65%; 
    /* 98A2B3 primary - 400 */

    --gray-25: #FCFCFD;
    --gray-50: #F9FAFB;
    --gray-100: #F2F4F7;
    --gray-200: #EAECF0;
    --gray-300: #D0D5DD;
    --gray-400: #98A2B3;
    --gray-500: #667085;
    --gray-600: #475467;
    --gray-700: #344054;
    --gray-800: #182230;
    --gray-900: #101828;
    --gray-950: #0C111D;

    --gray-dark-25: #FAFAFA;
    --gray-dark-50: #F5F5F6;
    --gray-dark-100: #F0F1F1;
    --gray-dark-200: #ECECED;
    --gray-dark-300: #CECFD2;
    --gray-dark-400: #94969C;
    --gray-dark-500: #85888E;
    --gray-dark-600: #61646C;
    --gray-dark-700: #344054;
    --gray-dark-800: #1F242F;
    --gray-dark-900: #161B26;
    --gray-dark-950: #0C111D;

    --brand-25: #FCFAFF;
    --brand-50: #F9F5FF;
    --brand-100: #F4EBFF;
    --brand-200: #E9D7FE;
    --brand-300: #D6BBFB;
    --brand-400: #B692F6;
    --brand-500: #9E77ED;
    --brand-600: #7F56D9;
    --brand-700: #6941C6;
    --brand-800: #53389E;
    --brand-900: #42307D;
    --brand-950: #2C1C5F;

    --error-25: #FFFBFA;
    --error-50: #FEF3F2;
    --error-100: #FEE4E2;
    --error-200: #FECDCA;
    --error-300: #FDA29B;
    --error-400: #F97066;
    --error-500: #F04438;
    --error-600: #D92D20;
    --error-700: #B42318;
    --error-800: #912018;
    --error-900: #7A271A;
    --error-950: #55160C;

    --warning-25: #FFFCF5;
    --warning-50: #FFFAEB;
    --warning-100: #FEF0C7;
    --warning-200: #FEDF89;
    --warning-300: #FEC84B;
    --warning-400: #FDB022;
    --warning-500: #F79009;
    --warning-600: #DC6803;
    --warning-700: #B54708;
    --warning-800: #93370D;
    --warning-900: #7A2E0E;
    --warning-950: #4E1D09;

    --success-25: #F6FEF9;
    --success-50: #ECFDF3;
    --success-100: #DCFAE6;
    --success-200: #ABEFC6;
    --success-300: #75E0A7;
    --success-400: #47CD89;
    --success-500: #17B26A;
    --success-600: #079455;
    --success-700: #067647;
    --success-800: #085D3A;
    --success-900: #074D31;
    --success-950: #053321;

    --gray-blue-25: #FCFCFD;
    --gray-blue-50: #F8F9FC;
    --gray-blue-100: #EAECF5;
    --gray-blue-200: #D5D9EB;
    --gray-blue-300: #B3B8DB;
    --gray-blue-400: #717BBC;
    --gray-blue-500: #4E5BA6;
    --gray-blue-600: #3E4784;
    --gray-blue-700: #363F72;
    --gray-blue-800: #293056;
    --gray-blue-900: #101323;
    --gray-blue-950: #0D0F1C;

    --gray-cool-25: #FCFCFD;
    --gray-cool-50: #F9F9FB;
    --gray-cool-100: #EFF1F5;
    --gray-cool-200: #DCDFEA;
    --gray-cool-300: #B9C0D4;
    --gray-cool-400: #7D89B0;
    --gray-cool-500: #5D6B98;
    --gray-cool-600: #4A5578;
    --gray-cool-700: #404968;
    --gray-cool-800: #30374F;
    --gray-cool-900: #111322;
    --gray-cool-950: #0E101B;
    
    --gray-modern-25: #FCFCFD;
    --gray-modern-50: #F8FAFC;
    --gray-modern-100: #EEF2F6;
    --gray-modern-200: #E3E8EF;
    --gray-modern-300: #CDD5DF;
    --gray-modern-400: #9AA4B2;
    --gray-modern-500: #697586;
    --gray-modern-600: #4B5565;
    --gray-modern-700: #364152;
    --gray-modern-800: #202939;
    --gray-modern-900: #121926;
    --gray-modern-950: #0D121C;

    --gray-neutral-25: #FCFCFD;
    --gray-neutral-50: #F9FAFB;
    --gray-neutral-100: #F3F4F6;
    --gray-neutral-200: #E5E7EB;
    --gray-neutral-300: #D2D6DB;
    --gray-neutral-400: #9DA4AE;
    --gray-neutral-500: #6C737F;
    --gray-neutral-600: #4D5761;
    --gray-neutral-700: #384250;
    --gray-neutral-800: #1F2A37;
    --gray-neutral-900: #111927;
    --gray-neutral-950: #0D121C;

    --gray-iron-25: #FCFCFC;
    --gray-iron-50: #FAFAFA;
    --gray-iron-100: #F4F4F5;
    --gray-iron-200: #E4E4E7;
    --gray-iron-300: #D1D1D6;
    --gray-iron-400: #A0A0AB;
    --gray-iron-500: #70707B;
    --gray-iron-600: #51525C;
    --gray-iron-700: #3F3F46;
    --gray-iron-800: #26272B;
    --gray-iron-900: #1A1A1E;
    --gray-iron-950: #131316;

    --gray-true-25: #FCFCFC;
    --gray-true-50: #FAFAFA;
    --gray-true-100: #F5F5F5;
    --gray-true-200: #E5E5E5;
    --gray-true-300: #D6D6D6;
    --gray-true-400: #A3A3A3;
    --gray-true-500: #737373;
    --gray-true-600: #525252;
    --gray-true-700: #424242;
    --gray-true-800: #292929;
    --gray-true-900: #141414;
    --gray-true-950: #0F0F0F;

    --gray-warm-25: #FDFDFC;
    --gray-warm-50: #FAFAF9;
    --gray-warm-100: #F5F5F4;
    --gray-warm-200: #E7E5E4;
    --gray-warm-300: #D7D3D0;
    --gray-warm-400: #A9A29D;
    --gray-warm-500: #79716B;
    --gray-warm-600: #57534E;
    --gray-warm-700: #44403C;
    --gray-warm-800: #292524;
    --gray-warm-900: #1C1917;
    --gray-warm-950: #171412;

    --moss-25: #FAFDF7;
    --moss-50: #F5FBEE;
    --moss-100: #E6F4D7;
    --moss-200: #CEEAB0;
    --moss-300: #ACDC79;
    --moss-400: #86CB3C;
    --moss-500: #669F2A;
    --moss-600: #4F7A21;
    --moss-700: #3F621A;
    --moss-800: #335015;
    --moss-900: #2B4212;
    --moss-950: #1A280B;

    --green-light-25: #FAFEF5;
    --green-light-50: #F3FEE7;
    --green-light-100: #E4FBCC;
    --green-light-200: #D0F8AB;
    --green-light-300: #A6EF67;
    --green-light-400: #85E13A;
    --green-light-500: #66C61C;
    --green-light-600: #4CA30D;
    --green-light-700: #3B7C0F;
    --green-light-800: #326212;
    --green-light-900: #2B5314;
    --green-light-950: #15290A;

    --green-25: #F6FEF9;
    --green-50: #EDFCF2;
    --green-100: #D3F8DF;
    --green-200: #AAF0C4;
    --green-300: #73E2A3;
    --green-400: #3CCB7F;
    --green-500: #16B364;
    --green-600: #099250;
    --green-700: #087443;
    --green-800: #095C37;
    --green-900: #084C2E;
    --green-950: #052E1C;

    --teal-25: #F6FEFC;
    --teal-50: #F0FDF9;
    --teal-100: #CCFBEF;
    --teal-200: #99F6E0;
    --teal-300: #5FE9D0;
    --teal-400: #2ED3B7;
    --teal-500: #15B79E;
    --teal-600: #0E9384;
    --teal-700: #107569;
    --teal-800: #125D56;
    --teal-900: #134E48;
    --teal-950: #0A2926;

    --cyan-25: #F5FEFF;
    --cyan-50: #ECFDFF;
    --cyan-100: #CFF9FE;
    --cyan-200: #A5F0FC;
    --cyan-300: #67E3F9;
    --cyan-400: #22CCEE;
    --cyan-500: #06AED4;
    --cyan-600: #088AB2;
    --cyan-700: #0E7090;
    --cyan-800: #155B75;
    --cyan-900: #164C63;
    --cyan-950: #0D2D3A;

    --blue-light-25: #F5FBFF;
    --blue-light-50: #F0F9FF;
    --blue-light-100: #E0F2FE;
    --blue-light-200: #B9E6FE;
    --blue-light-300: #7CD4FD;
    --blue-light-400: #36BFFA;
    --blue-light-500: #0BA5EC;
    --blue-light-600: #0086C9;
    --blue-light-700: #026AA2;
    --blue-light-800: #065986;
    --blue-light-900: #0B4A6F;
    --blue-light-950: #062C41;

    --blue-25: #F5FAFF;
    --blue-50: #EFF8FF;
    --blue-100: #D1E9FF;
    --blue-200: #B2DDFF;
    --blue-300: #84CAFF;
    --blue-400: #53B1FD;
    --blue-500: #2E90FA;
    --blue-600: #1570EF;
    --blue-700: #175CD3;
    --blue-800: #1849A9;
    --blue-900: #194185;
    --blue-950: #102A56;

    --blue-dark-25: #F5F8FF;
    --blue-dark-50: #EFF4FF;
    --blue-dark-100: #D1E0FF;
    --blue-dark-200: #B2CCFF;
    --blue-dark-300: #84ADFF;
    --blue-dark-400: #528BFF;
    --blue-dark-500: #2970FF;
    --blue-dark-600: #155EEF;
    --blue-dark-700: #004EEB;
    --blue-dark-800: #0040C1;
    --blue-dark-900: #00359E;
    --blue-dark-950: #002266;

    --indigo-25: #F5F8FF;
    --indigo-50: #EEF4FF;
    --indigo-100: #E0EAFF;
    --indigo-200: #C7D7FE;
    --indigo-300: #A4BCFD;
    --indigo-400: #8098F9;
    --indigo-500: #6172F3;
    --indigo-600: #444CE7;
    --indigo-700: #3538CD;
    --indigo-800: #2D31A6;
    --indigo-900: #2D3282;
    --indigo-950: #1F235B;

    --violet-25: #FBFAFF;
    --violet-50: #F5F3FF;
    --violet-100: #ECE9FE;
    --violet-200: #DDD6FE;
    --violet-300: #C3B5FD;
    --violet-400: #A48AFB;
    --violet-500: #875BF7;
    --violet-600: #7839EE;
    --violet-700: #6927DA;
    --violet-800: #5720B7;
    --violet-900: #491C96;
    --violet-950: #2E125E;

    --purple-25: #FAFAFF;
    --purple-50: #F4F3FF;
    --purple-100: #EBE9FE;
    --purple-200: #D9D6FE;
    --purple-300: #BDB4FE;
    --purple-400: #9B8AFB;
    --purple-500: #7A5AF8;
    --purple-600: #6938EF;
    --purple-700: #5925DC;
    --purple-800: #4A1FB8;
    --purple-900: #3E1C96;
    --purple-950: #27115F;

    --fuchsia-25: #FEFAFF;
    --fuchsia-50: #FDF4FF;
    --fuchsia-100: #FBE8FF;
    --fuchsia-200: #F6D0FE;
    --fuchsia-300: #EEAAFD;
    --fuchsia-400: #E478FA;
    --fuchsia-500: #D444F1;
    --fuchsia-600: #BA24D5;
    --fuchsia-700: #9F1AB1;
    --fuchsia-800: #821890;
    --fuchsia-900: #6F1877;
    --fuchsia-950: #47104C;

    --pink-25: #FEF6FB;
    --pink-50: #FDF2FA;
    --pink-100: #FCE7F6;
    --pink-200: #FCCEEE;
    --pink-300: #FAA7E0;
    --pink-400: #F670C7;
    --pink-500: #EE46BC;
    --pink-600: #DD2590;
    --pink-700: #C11574;
    --pink-800: #9E165F;
    --pink-900: #851651;
    --pink-950: #4E0D30;

    --rose-25: #FFF5F6;
    --rose-50: #FFF1F3;
    --rose-100: #FFE4E8;
    --rose-200: #FECDD6;
    --rose-300: #FEA3B4;
    --rose-400: #FD6F8E;
    --rose-500: #F63D68;
    --rose-600: #E31B54;
    --rose-700: #C01048;
    --rose-800: #A11043;
    --rose-900: #89123E;
    --rose-950: #510B24;

    --orange-dark-25: #FFF9F5;
    --orange-dark-50: #FFF4ED;
    --orange-dark-100: #FFE6D5;
    --orange-dark-200: #FFD6AE;
    --orange-dark-300: #FF9C66;
    --orange-dark-400: #FF692E;
    --orange-dark-500: #FF4405;
    --orange-dark-600: #E62E05;
    --orange-dark-700: #BC1B06;
    --orange-dark-800: #97180C;
    --orange-dark-900: #771A0D;
    --orange-dark-950: #57130A;

    --orange-25: #FEFAF5;
    --orange-50: #FEF6EE;
    --orange-100: #FDEAD7;
    --orange-200: #F9DBAF;
    --orange-300: #F7B27A;
    --orange-400: #F38744;
    --orange-500: #EF6820;
    --orange-600: #E04F16;
    --orange-700: #B93815;
    --orange-800: #932F19;
    --orange-900: #772917;
    --orange-950: #511C10;

    --yellow-25: #FEFDF0;
    --yellow-50: #FEFBE8;
    --yellow-100: #FEF7C3;
    --yellow-200: #FEEE95;
    --yellow-300: #FDE272;
    --yellow-400: #FAC515;
    --yellow-500: #EAAA08;
    --yellow-600: #CA8504;
    --yellow-700: #A15C07;
    --yellow-800: #854A0E;
    --yellow-900: #713B12;
    --yellow-950: #542C0D;
    
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: #333741;
    --input: #333741;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;

    --purple: 0 100% 100%;
    --purple-foreground: 259 63% 59%;

    --smoke: 221 21% 9%;
    --paragraph: 225 4% 60%;
    --smokegray: 221 21% 9%;

    --text-900: 240 5% 96%; 
    /* F5F5F6 primary - 900 */

     --text-700: 225 4% 82%; 
    /* CECFD2 primary - 700 */

    --text-600: 225 4% 60%; 
    /* 94969C primary - 600 */

    --text-500: 225 4% 60%; 
    /* 94969C primary - 500 */

    --text-400: 220 4% 54%; 
    /* 85888E primary - 400 */

    --gray-25: #0C111D; /* Original --gray-950 */
    --gray-50: #101828; /* Original --gray-900 */
    --gray-100: #182230; /* Original --gray-800 */
    --gray-200: #344054; /* Original --gray-700 */
    --gray-300: #475467; /* Original --gray-600 */
    --gray-400: #667085; /* Original --gray-500 */
    --gray-500: #98A2B3; /* Original --gray-400 */
    --gray-600: #D0D5DD; /* Original --gray-300 */
    --gray-700: #EAECF0; /* Original --gray-200 */
    --gray-800: #F2F4F7; /* Original --gray-100 */
    --gray-900: #F9FAFB; /* Original --gray-50 */
    --gray-950: #FCFCFD; /* Original --gray-25 */

   
    --gray-dark-25: #0C111D; /* Original --gray-dark-950 */
    --gray-dark-50: #161B26; /* Original --gray-dark-900 */
    --gray-dark-100: #1F242F; /* Original --gray-dark-800 */
    --gray-dark-200: #333741; /* Original --gray-dark-700 */
    --gray-dark-300: #61646C; /* Original --gray-dark-600 */
    --gray-dark-400: #85888E; /* Original --gray-dark-500 */
    --gray-dark-500: #94969C; /* Original --gray-dark-400 */
    --gray-dark-600: #CECFD2; /* Original --gray-dark-300 */
    --gray-dark-700: #ECECED; /* Original --gray-dark-200 */
    --gray-dark-800: #F0F1F1; /* Original --gray-dark-100 */
    --gray-dark-900: #F5F5F6; /* Original --gray-dark-50 */
    --gray-dark-950: #FAFAFA; /* Original --gray-dark-25 */
   

    --brand-25: #2C1C5F; /* Original --brand-950 */
    --brand-50: #42307D; /* Original --brand-900 */
    --brand-100: #53389E; /* Original --brand-800 */
    --brand-200: #6941C6; /* Original --brand-700 */
    --brand-300: #7F56D9; /* Original --brand-600 */
    --brand-400: #9E77ED; /* Original --brand-500 */
    --brand-500: #B692F6; /* Original --brand-400 */
    --brand-600: #D6BBFB; /* Original --brand-300 */
    --brand-700: #E9D7FE; /* Original --brand-200 */
    --brand-800: #F4EBFF; /* Original --brand-100 */
    --brand-900: #F9F5FF; /* Original --brand-50 */
    --brand-950: #FCFAFF; /* Original --brand-25 */

    --error-25: #55160C; /* Original --error-950 */
    --error-50: #7A271A; /* Original --error-900 */
    --error-100: #912018; /* Original --error-800 */
    --error-200: #B42318; /* Original --error-700 */
    --error-300: #D92D20; /* Original --error-600 */
    --error-400: #F04438; /* Original --error-500 */
    --error-500: #F97066; /* Original --error-400 */
    --error-600: #FDA29B; /* Original --error-300 */
    --error-700: #FECDCA; /* Original --error-200 */
    --error-800: #FEE4E2; /* Original --error-100 */
    --error-900: #FEF3F2; /* Original --error-50 */
    --error-950: #FFFBFA; /* Original --error-25 */

    --warning-25: #4E1D09; /* Original --warning-950 */
    --warning-50: #7A2E0E; /* Original --warning-900 */
    --warning-100: #93370D; /* Original --warning-800 */
    --warning-200: #B54708; /* Original --warning-700 */
    --warning-300: #DC6803; /* Original --warning-600 */
    --warning-400: #F79009; /* Original --warning-500 */
    --warning-500: #FDB022; /* Original --warning-400 */
    --warning-600: #FEC84B; /* Original --warning-300 */
    --warning-700: #FEDF89; /* Original --warning-200 */
    --warning-800: #FEF0C7; /* Original --warning-100 */
    --warning-900: #FFFAEB; /* Original --warning-50 */
    --warning-950: #FFFCF5; /* Original --warning-25 */

    --success-25: #053321; /* Original --success-950 */
    --success-50: #074D31; /* Original --success-900 */
    --success-100: #085D3A; /* Original --success-800 */
    --success-200: #067647; /* Original --success-700 */
    --success-300: #079455; /* Original --success-600 */
    --success-400: #17B26A; /* Original --success-500 */
    --success-500: #47CD89; /* Original --success-400 */
    --success-600: #75E0A7; /* Original --success-300 */
    --success-700: #ABEFC6; /* Original --success-200 */
    --success-800: #DCFAE6; /* Original --success-100 */
    --success-900: #ECFDF3; /* Original --success-50 */
    --success-950: #F6FEF9; /* Original --success-25 */

    --gray-blue-25: #0D0F1C; /* Original --gray-blue-950 */
    --gray-blue-50: #101323; /* Original --gray-blue-900 */
    --gray-blue-100: #293056; /* Original --gray-blue-800 */
    --gray-blue-200: #363F72; /* Original --gray-blue-700 */
    --gray-blue-300: #3E4784; /* Original --gray-blue-600 */
    --gray-blue-400: #4E5BA6; /* Original --gray-blue-500 */
    --gray-blue-500: #717BBC; /* Original --gray-blue-400 */
    --gray-blue-600: #B3B8DB; /* Original --gray-blue-300 */
    --gray-blue-700: #D5D9EB; /* Original --gray-blue-200 */
    --gray-blue-800: #EAECF5; /* Original --gray-blue-100 */
    --gray-blue-900: #F8F9FC; /* Original --gray-blue-50 */
    --gray-blue-950: #FCFCFD; /* Original --gray-blue-25 */

    --gray-cool-25: #0E101B; /* Original --gray-cool-950 */
    --gray-cool-50: #111322; /* Original --gray-cool-900 */
    --gray-cool-100: #30374F; /* Original --gray-cool-800 */
    --gray-cool-200: #404968; /* Original --gray-cool-700 */
    --gray-cool-300: #4A5578; /* Original --gray-cool-600 */
    --gray-cool-400: #5D6B98; /* Original --gray-cool-500 */
    --gray-cool-500: #7D89B0; /* Original --gray-cool-400 */
    --gray-cool-600: #B9C0D4; /* Original --gray-cool-300 */
    --gray-cool-700: #DCDFEA; /* Original --gray-cool-200 */
    --gray-cool-800: #EFF1F5; /* Original --gray-cool-100 */
    --gray-cool-900: #F9F9FB; /* Original --gray-cool-50 */
    --gray-cool-950: #FCFCFD; /* Original --gray-cool-25 */

    --gray-modern-25: #0D121C; /* Original --gray-modern-950 */
    --gray-modern-50: #121926; /* Original --gray-modern-900 */
    --gray-modern-100: #202939; /* Original --gray-modern-800 */
    --gray-modern-200: #364152; /* Original --gray-modern-700 */
    --gray-modern-300: #4B5565; /* Original --gray-modern-600 */
    --gray-modern-400: #697586; /* Original --gray-modern-500 */
    --gray-modern-500: #9AA4B2; /* Original --gray-modern-400 */
    --gray-modern-600: #CDD5DF; /* Original --gray-modern-300 */
    --gray-modern-700: #E3E8EF; /* Original --gray-modern-200 */
    --gray-modern-800: #EEF2F6; /* Original --gray-modern-100 */
    --gray-modern-900: #F8FAFC; /* Original --gray-modern-50 */
    --gray-modern-950: #FCFCFD; /* Original --gray-modern-25 */

    --gray-neutral-25: #0D121C; /* Original --gray-neutral-950 */
    --gray-neutral-50: #111927; /* Original --gray-neutral-900 */
    --gray-neutral-100: #1F2A37; /* Original --gray-neutral-800 */
    --gray-neutral-200: #384250; /* Original --gray-neutral-700 */
    --gray-neutral-300: #4D5761; /* Original --gray-neutral-600 */
    --gray-neutral-400: #6C737F; /* Original --gray-neutral-500 */
    --gray-neutral-500: #9DA4AE; /* Original --gray-neutral-400 */
    --gray-neutral-600: #D2D6DB; /* Original --gray-neutral-300 */
    --gray-neutral-700: #E5E7EB; /* Original --gray-neutral-200 */
    --gray-neutral-800: #F3F4F6; /* Original --gray-neutral-100 */
    --gray-neutral-900: #F9FAFB; /* Original --gray-neutral-50 */
    --gray-neutral-950: #FCFCFD; /* Original --gray-neutral-25 */

    --gray-iron-25: #131316; /* Original --gray-iron-950 */
    --gray-iron-50: #1A1A1E; /* Original --gray-iron-900 */
    --gray-iron-100: #26272B; /* Original --gray-iron-800 */
    --gray-iron-200: #3F3F46; /* Original --gray-iron-700 */
    --gray-iron-300: #51525C; /* Original --gray-iron-600 */
    --gray-iron-400: #70707B; /* Original --gray-iron-500 */
    --gray-iron-500: #A0A0AB; /* Original --gray-iron-400 */
    --gray-iron-600: #D1D1D6; /* Original --gray-iron-300 */
    --gray-iron-700: #E4E4E7; /* Original --gray-iron-200 */
    --gray-iron-800: #F4F4F5; /* Original --gray-iron-100 */
    --gray-iron-900: #FAFAFA; /* Original --gray-iron-50 */
    --gray-iron-950: #FCFCFC; /* Original --gray-iron-25 */

    --gray-true-25: #0F0F0F; /* Original --gray-true-950 */
    --gray-true-50: #141414; /* Original --gray-true-900 */
    --gray-true-100: #292929; /* Original --gray-true-800 */
    --gray-true-200: #424242; /* Original --gray-true-700 */
    --gray-true-300: #525252; /* Original --gray-true-600 */
    --gray-true-400: #737373; /* Original --gray-true-500 */
    --gray-true-500: #A3A3A3; /* Original --gray-true-400 */
    --gray-true-600: #D6D6D6; /* Original --gray-true-300 */
    --gray-true-700: #E5E5E5; /* Original --gray-true-200 */
    --gray-true-800: #F5F5F5; /* Original --gray-true-100 */
    --gray-true-900: #FAFAFA; /* Original --gray-true-50 */
    --gray-true-950: #FCFCFC; /* Original --gray-true-25 */

    --gray-warm-25: #171412; /* Original --gray-warm-950 */
    --gray-warm-50: #1C1917; /* Original --gray-warm-900 */
    --gray-warm-100: #292524; /* Original --gray-warm-800 */
    --gray-warm-200: #44403C; /* Original --gray-warm-700 */
    --gray-warm-300: #57534E; /* Original --gray-warm-600 */
    --gray-warm-400: #79716B; /* Original --gray-warm-500 */
    --gray-warm-500: #A9A29D; /* Original --gray-warm-400 */
    --gray-warm-600: #D7D3D0; /* Original --gray-warm-300 */
    --gray-warm-700: #E7E5E4; /* Original --gray-warm-200 */
    --gray-warm-800: #F5F5F4; /* Original --gray-warm-100 */
    --gray-warm-900: #FAFAF9; /* Original --gray-warm-50 */
    --gray-warm-950: #FDFDFC; /* Original --gray-warm-25 */

    --moss-25: #1A280B; /* Original --moss-950 */
    --moss-50: #2B4212; /* Original --moss-900 */
    --moss-100: #335015; /* Original --moss-800 */
    --moss-200: #3F621A; /* Original --moss-700 */
    --moss-300: #4F7A21; /* Original --moss-600 */
    --moss-400: #669F2A; /* Original --moss-500 */
    --moss-500: #86CB3C; /* Original --moss-400 */
    --moss-600: #ACDC79; /* Original --moss-300 */
    --moss-700: #CEEAB0; /* Original --moss-200 */
    --moss-800: #E6F4D7; /* Original --moss-100 */
    --moss-900: #F5FBEE; /* Original --moss-50 */
    --moss-950: #FAFDF7; /* Original --moss-25 */

    --green-light-25: #15290A; /* Original --green-light-950 */
    --green-light-50: #2B5314; /* Original --green-light-900 */
    --green-light-100: #326212; /* Original --green-light-800 */
    --green-light-200: #3B7C0F; /* Original --green-light-700 */
    --green-light-300: #4CA30D; /* Original --green-light-600 */
    --green-light-400: #66C61C; /* Original --green-light-500 */
    --green-light-500: #85E13A; /* Original --green-light-400 */
    --green-light-600: #A6EF67; /* Original --green-light-300 */
    --green-light-700: #D0F8AB; /* Original --green-light-200 */
    --green-light-800: #E4FBCC; /* Original --green-light-100 */
    --green-light-900: #F3FEE7; /* Original --green-light-50 */
    --green-light-950: #FAFEF5; /* Original --green-light-25 */

    --green-25: #052E1C; /* Original --green-950 */
    --green-50: #084C2E; /* Original --green-900 */
    --green-100: #095C37; /* Original --green-800 */
    --green-200: #087443; /* Original --green-700 */
    --green-300: #099250; /* Original --green-600 */
    --green-400: #16B364; /* Original --green-500 */
    --green-500: #3CCB7F; /* Original --green-400 */
    --green-600: #73E2A3; /* Original --green-300 */
    --green-700: #AAF0C4; /* Original --green-200 */
    --green-800: #D3F8DF; /* Original --green-100 */
    --green-900: #EDFCF2; /* Original --green-50 */
    --green-950: #F6FEF9; /* Original --green-25 */

    --teal-25: #0A2926; /* Original --teal-950 */
    --teal-50: #134E48; /* Original --teal-900 */
    --teal-100: #125D56; /* Original --teal-800 */
    --teal-200: #107569; /* Original --teal-700 */
    --teal-300: #0E9384; /* Original --teal-600 */
    --teal-400: #15B79E; /* Original --teal-500 */
    --teal-500: #2ED3B7; /* Original --teal-400 */
    --teal-600: #5FE9D0; /* Original --teal-300 */
    --teal-700: #99F6E0; /* Original --teal-200 */
    --teal-800: #CCFBEF; /* Original --teal-100 */
    --teal-900: #F0FDF9; /* Original --teal-50 */
    --teal-950: #F6FEFC; /* Original --teal-25 */ 

    --cyan-25: #0D2D3A; /* Original --cyan-950 */
    --cyan-50: #164C63; /* Original --cyan-900 */
    --cyan-100: #155B75; /* Original --cyan-800 */
    --cyan-200: #0E7090; /* Original --cyan-700 */
    --cyan-300: #088AB2; /* Original --cyan-600 */
    --cyan-400: #06AED4; /* Original --cyan-500 */
    --cyan-500: #22CCEE; /* Original --cyan-400 */
    --cyan-600: #67E3F9; /* Original --cyan-300 */
    --cyan-700: #A5F0FC; /* Original --cyan-200 */
    --cyan-800: #CFF9FE; /* Original --cyan-100 */
    --cyan-900: #ECFDFF; /* Original --cyan-50 */
    --cyan-950: #F5FEFF; /* Original --cyan-25 */

    --blue-light-25: #062C41; /* Original --blue-light-950 */
    --blue-light-50: #0B4A6F; /* Original --blue-light-900 */
    --blue-light-100: #065986; /* Original --blue-light-800 */
    --blue-light-200: #026AA2; /* Original --blue-light-700 */
    --blue-light-300: #0086C9; /* Original --blue-light-600 */
    --blue-light-400: #0BA5EC; /* Original --blue-light-500 */
    --blue-light-500: #36BFFA; /* Original --blue-light-400 */
    --blue-light-600: #7CD4FD; /* Original --blue-light-300 */
    --blue-light-700: #B9E6FE; /* Original --blue-light-200 */
    --blue-light-800: #E0F2FE; /* Original --blue-light-100 */
    --blue-light-900: #F0F9FF; /* Original --blue-light-50 */
    --blue-light-950: #F5FBFF; /* Original --blue-light-25 */

    --blue-25: #0D2D3A; /* Original --blue-950 */
    --blue-50: #164C63; /* Original --blue-900 */
    --blue-100: #155B75; /* Original --blue-800 */
    --blue-200: #0E7090; /* Original --blue-700 */
    --blue-300: #088AB2; /* Original --blue-600 */
    --blue-400: #06AED4; /* Original --blue-500 */
    --blue-500: #22CCEE; /* Original --blue-400 */
    --blue-600: #67E3F9; /* Original --blue-300 */
    --blue-700: #A5F0FC; /* Original --blue-200 */
    --blue-800: #EFF8FF; /* Original --blue-100 */
    --blue-900: #F5FAFF; /* Original --blue-50 */
    --blue-950: #F5FAFF; /* Original --blue-25 */

    --blue-dark-25: #002266; /* Original --blue-dark-950 */
    --blue-dark-50: #00359E; /* Original --blue-dark-900 */
    --blue-dark-100: #0040C1; /* Original --blue-dark-800 */
    --blue-dark-200: #004EEB; /* Original --blue-dark-700 */
    --blue-dark-300: #155EEF; /* Original --blue-dark-600 */
    --blue-dark-400: #2970FF; /* Original --blue-dark-500 */
    --blue-dark-500: #528BFF; /* Original --blue-dark-400 */
    --blue-dark-600: #84ADFF; /* Original --blue-dark-300 */
    --blue-dark-700: #B2CCFF; /* Original --blue-dark-200 */
    --blue-dark-800: #D1E0FF; /* Original --blue-dark-100 */
    --blue-dark-900: #EFF4FF; /* Original --blue-dark-50 */
    --blue-dark-950: #F5F8FF; /* Original --blue-dark-25 */

    --indigo-25: #1F235B; /* Original --indigo-950 */
    --indigo-50: #2D3282; /* Original --indigo-900 */
    --indigo-100: #2D31A6; /* Original --indigo-800 */
    --indigo-200: #3538CD; /* Original --indigo-700 */
    --indigo-300: #444CE7; /* Original --indigo-600 */
    --indigo-400: #6172F3; /* Original --indigo-500 */
    --indigo-500: #8098F9; /* Original --indigo-400 */
    --indigo-600: #A4BCFD; /* Original --indigo-300 */
    --indigo-700: #C7D7FE; /* Original --indigo-200 */
    --indigo-800: #E0EAFF; /* Original --indigo-100 */
    --indigo-900: #EEF4FF; /* Original --indigo-50 */
    --indigo-950: #F5F8FF; /* Original --indigo-25 */

    --violet-25: #2E125E; /* Original --violet-950 */
    --violet-50: #491C96; /* Original --violet-900 */
    --violet-100: #5720B7; /* Original --violet-800 */
    --violet-200: #6927DA; /* Original --violet-700 */
    --violet-300: #7839EE; /* Original --violet-600 */
    --violet-400: #875BF7; /* Original --violet-500 */
    --violet-500: #A48AFB; /* Original --violet-400 */
    --violet-600: #C3B5FD; /* Original --violet-300 */
    --violet-700: #DDD6FE; /* Original --violet-200 */
    --violet-800: #ECE9FE; /* Original --violet-100 */
    --violet-900: #F5F3FF; /* Original --violet-50 */
    --violet-950: #FBFAFF; /* Original --violet-25 */

    --purple-25: #27115F; /* Original --purple-950 */
    --purple-50: #3E1C96; /* Original --purple-900 */
    --purple-100: #4A1FB8; /* Original --purple-800 */
    --purple-200: #5925DC; /* Original --purple-700 */
    --purple-300: #6938EF; /* Original --purple-600 */
    --purple-400: #7A5AF8; /* Original --purple-500 */
    --purple-500: #9B8AFB; /* Original --purple-400 */
    --purple-600: #BDB4FE; /* Original --purple-300 */
    --purple-700: #D9D6FE; /* Original --purple-200 */
    --purple-800: #EBE9FE; /* Original --purple-100 */
    --purple-900: #F4F3FF; /* Original --purple-50 */
    --purple-950: #FAFAFF; /* Original --purple-25 */

    --fuchsia-25: #47104C; /* Original --fuchsia-950 */
    --fuchsia-50: #6F1877; /* Original --fuchsia-900 */
    --fuchsia-100: #821890; /* Original --fuchsia-800 */
    --fuchsia-200: #9F1AB1; /* Original --fuchsia-700 */
    --fuchsia-300: #BA24D5; /* Original --fuchsia-600 */
    --fuchsia-400: #D444F1; /* Original --fuchsia-500 */
    --fuchsia-500: #E478FA; /* Original --fuchsia-400 */
    --fuchsia-600: #EEAAFD; /* Original --fuchsia-300 */
    --fuchsia-700: #F6D0FE; /* Original --fuchsia-200 */
    --fuchsia-800: #FBE8FF; /* Original --fuchsia-100 */
    --fuchsia-900: #FDF4FF; /* Original --fuchsia-50 */
    --fuchsia-950: #FEFAFF; /* Original --fuchsia-25 */

    --pink-25: #4E0D30; /* Original --pink-950 */
    --pink-50: #851651; /* Original --pink-900 */
    --pink-100: #9E165F; /* Original --pink-800 */
    --pink-200: #C11574; /* Original --pink-700 */
    --pink-300: #DD2590; /* Original --pink-600 */
    --pink-400: #EE46BC; /* Original --pink-500 */
    --pink-500: #F670C7; /* Original --pink-400 */
    --pink-600: #FAA7E0; /* Original --pink-300 */
    --pink-700: #FCCEEE; /* Original --pink-200 */
    --pink-800: #FCE7F6; /* Original --pink-100 */
    --pink-900: #FDF2FA; /* Original --pink-50 */
    --pink-950: #FEF6FB; /* Original --pink-25 */

    --rose-25: #510B24; /* Original --rose-950 */
    --rose-50: #89123E; /* Original --rose-900 */
    --rose-100: #A11043; /* Original --rose-800 */
    --rose-200: #C01048; /* Original --rose-700 */
    --rose-300: #E31B54; /* Original --rose-600 */
    --rose-400: #F63D68; /* Original --rose-500 */
    --rose-500: #FD6F8E; /* Original --rose-400 */
    --rose-600: #FEA3B4; /* Original --rose-300 */
    --rose-700: #FECDD6; /* Original --rose-200 */
    --rose-800: #FFE4E8; /* Original --rose-100 */
    --rose-900: #FFF1F3; /* Original --rose-50 */
    --rose-950: #FFF5F6; /* Original --rose-25 */

    --orange-dark-25: #57130A; /* Original --orange-dark-950 */
    --orange-dark-50: #771A0D; /* Original --orange-dark-900 */
    --orange-dark-100: #97180C; /* Original --orange-dark-800 */
    --orange-dark-200: #BC1B06; /* Original --orange-dark-700 */
    --orange-dark-300: #E62E05; /* Original --orange-dark-600 */
    --orange-dark-400: #FF4405; /* Original --orange-dark-500 */
    --orange-dark-500: #FF692E; /* Original --orange-dark-400 */
    --orange-dark-600: #FF9C66; /* Original --orange-dark-300 */
    --orange-dark-700: #FFD6AE; /* Original --orange-dark-200 */
    --orange-dark-800: #FFE6D5; /* Original --orange-dark-100 */
    --orange-dark-900: #FFF4ED; /* Original --orange-dark-50 */
    --orange-dark-950: #FFF9F5; /* Original --orange-dark-25 */

    --orange-25: #511C10; /* Original --orange-950 */
    --orange-50: #772917; /* Original --orange-900 */
    --orange-100: #932F19; /* Original --orange-800 */
    --orange-200: #B93815; /* Original --orange-700 */
    --orange-300: #E04F16; /* Original --orange-600 */
    --orange-400: #EF6820; /* Original --orange-500 */
    --orange-500: #F38744; /* Original --orange-400 */
    --orange-600: #F7B27A; /* Original --orange-300 */
    --orange-700: #F9DBAF; /* Original --orange-200 */
    --orange-800: #FDEAD7; /* Original --orange-100 */
    --orange-900: #FEF6EE; /* Original --orange-50 */
    --orange-950: #FEFAF5; /* Original --orange-25 */

    --yellow-25: #542C0D; /* Original --yellow-950 */
    --yellow-50: #713B12; /* Original --yellow-900 */
    --yellow-100: #854A0E; /* Original --yellow-800 */
    --yellow-200: #A15C07; /* Original --yellow-700 */
    --yellow-300: #CA8504; /* Original --yellow-600 */
    --yellow-400: #EAAA08; /* Original --yellow-500 */
    --yellow-500: #FAC515; /* Original --yellow-400 */
    --yellow-600: #FDE272; /* Original --yellow-300 */
    --yellow-700: #FEEE95; /* Original --yellow-200 */
    --yellow-800: #FEF7C3; /* Original --yellow-100 */
    --yellow-900: #FEFBE8; /* Original --yellow-50 */
    --yellow-950: #FEFDF0; /* Original --yellow-25 */
  
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}


.dark img[src$=".svg"]:not(.no-invert), .dark img[src*="icons/"]:not(.no-invert) {
  filter: brightness(0) invert(1);
}
/* .dark .bg__nodata{background-image: rgba(255, 255, 255, 0.1); } */