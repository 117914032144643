.paginationWrapper .pagination {
    display: inline-flex;
    justify-content: center;
    padding: 10px 0;
    list-style-type: none;
}

.paginationWrapper .pagination a {
    min-width: 40px;
    padding: 5px 10px;
    text-decoration: none;
    transition: color 0.3s;
    position: relative;
    display: inline-flex;
    text-align: center;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: 36px;
}

.paginationWrapper .pagination .active a {
    background-color: hsl(var(--secondary));
    color: hsl(var(--foreground));
    border-radius: var(--radius);
    pointer-events: none;
}

.paginationWrapper .pagination li:first-child a {
    border-radius: var(--radius);
    border-width: 1px;
    /* border-color: hsl(var(--border)) ; */
    margin-right: 16px;
    padding-right: 15px;

}

.paginationWrapper .pagination li:last-child a {
    border-radius: var(--radius);
    border-width: 1px;
    /* border-color: hsl(var(--border)) ; */
    margin-left: 16px;
    padding-left: 15px;
}